import { Component, Input } from '@angular/core'

@Component({
  selector: 'sb-channel-type-logo',
  template: `
    @if (type === 'facebook') {
      <img alt="facebook" class="channel__type fb" src="assets/img/facebook-logo.png" />
    }
    @if (type === 'instagram') {
      <img alt="instagram" class="channel__type ig" src="assets/img/instagram-logox200.png" />
    }
    @if (type === 'facebook-ad-account') {
      <img alt="facebook ads" class="channel__type fb-ads" src="assets/img/fb-ads-logo.png" />
    }
    @if (type === 'linkedin-organization') {
      <img alt="linkedin organization" class="channel__type linkedin-organization" src="assets/img/linkedin-logo.png" />
    }
  `,
  styles: `
    .channel__type {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16px;
      z-index: 3;
      background-color: white;
      padding: 0.05rem;

      &.ig {
        border-radius: 0.3rem;
      }

      &.fb {
        border-radius: 50%;
      }

      &.fb-ads {
        border-radius: 0.5rem;
        padding: 0.1rem;
        width: 17px;
        border: 1px solid grey;
      }

      &.linkedin-organization {
        border-radius: 0.2rem;
        padding: 1px;
        width: 17px;
      }
    }
  `,
  standalone: true,
  imports: [],
})
export class ChannelTypeLogoComponent {
  @Input() type: string
}
